import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);


export const mainTabControllerSlice = createSlice({
    name: 'mainTabControllerSlice',
    initialState: {
        sidebarCollapsed: false,
        dateFilter: {
            startDate: dayjs().subtract(1, 'week').endOf('day').format('YYYY-MM-DD'),
            finishDate: dayjs().startOf('day').format('YYYY-MM-DD'),
        },
        reportPageMainTabTotalCount: null,
        reportPageMainTabGraph: null,
        isReportPageMainTabTotalCountLoading: false,
        isReportPageMainTabGraphLoading: false,
        visibleCharts: {
            dailyCollectedContainers: true,
            dailyRouteTime: true,
            mostWasteCollectedStreets: true,
            dailyDistance: true,
            dailyBreakTime: true,
            dailyTrashDumpTime: true,
            dailySuccessRatio: true,
            topAlarmContainers: true
        }
    },

    reducers: {
        setSidebarCollapsed: (state, action) => {
            state.sidebarCollapsed = action.payload
        },
        setDateFilter: (state, action) => {
            state.dateFilter = action.payload
        },

        setReportPageMainTabTotalCount: (state, action) => {
            state.reportPageMainTabTotalCount = action.payload
            state.isReportPageMainTabTotalCountLoading = false
        },

        setReportPageMainTabGraph: (state, action) => {
            state.reportPageMainTabGraph = action.payload
            state.isReportPageMainTabGraphLoading = false
        },

        setIsReportPageMainTabTotalCountLoading: (state, action) => {
            state.isReportPageMainTabTotalCountLoading = action.payload
        },

        setIsReportPageMainTabGraphLoading: (state, action) => {
            state.isReportPageMainTabGraphLoading = action.payload
        },
        
        setChartVisibility: (state, action) => {
            const { chartId, isVisible } = action.payload;
            state.visibleCharts[chartId] = isVisible;
        },
        
        setAllChartsVisibility: (state, action) => {
            state.visibleCharts = action.payload;
        }
    }
})


export const {
    setSidebarCollapsed,
    setDateFilter,
    setReportPageMainTabTotalCount,
    setReportPageMainTabGraph,
    setIsReportPageMainTabTotalCountLoading,
    setIsReportPageMainTabGraphLoading,
    setChartVisibility,
    setAllChartsVisibility
} = mainTabControllerSlice.actions

export default mainTabControllerSlice.reducer


