import React, { lazy, Suspense, useState } from "react";

import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AntdModal from "./components/antdModal/AntdModal";
import PageLoading from "./components/loadings/PageLoading";
import { routes } from "./core/route/router";
import { REACT_APP_GOOGLE_MAPS_API_KEY } from "./core/constance/ApiKeys";
import { LoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";


const MedicalWasteHomePage = lazy(() => import('./pages/medical_waste_pocket_pages/home_page'))
const ClassicModal = lazy(() => import('./components/modal/ClassicModal'))
const Toasty = lazy(() => import('./components/toasty/Toasty'))
const ConstanceSettings = lazy(() => import('./pages/with_device_pocket_pages/constance_settings/ConstanceSettings'))
const Containers = lazy(() => import('./pages/with_device_pocket_pages/containers/Containers2'))
const DeviceManagement = lazy(() => import('./pages/with_device_pocket_pages/device_management/DeviceManagement'))
const DeviceSettings = lazy(() => import('./pages/with_device_pocket_pages/device_settings/DeviceSettings'))
const HomePage = lazy(() => import('./pages/with_device_pocket_pages/home_page/HomePage'))
const Login = lazy(() => import('./pages/with_device_pocket_pages/login/Login'))
const Maps = lazy(() => import('./pages/with_device_pocket_pages/maps/Maps2'))
const MapRouteManagement = lazy(() => import('./pages/with_device_pocket_pages/map_route_management/MapRouteManagement'))
const RouteControl = lazy(() => import('./pages/with_device_pocket_pages/map_route_management/RouteControl'))
const Notifications = lazy(() => import('./pages/with_device_pocket_pages/notifications/Notifications'))
const NotFound = lazy(() => import('./pages/with_device_pocket_pages/not_found/NotFound'))
const DeviceBoxOperation = lazy(() => import('./pages/with_device_pocket_pages/operations/DeviceBoxOperation'))
const DeviceQrOperation = lazy(() => import('./pages/with_device_pocket_pages/operations/DeviceQrOperation'))
const DeviceShipment = lazy(() => import('./pages/with_device_pocket_pages/operations/DeviceShipment'))
const DeviceTest = lazy(() => import('./pages/with_device_pocket_pages/operations/DeviceTest'))
const ShippingCustomerManagement = lazy(() => import('./pages/with_device_pocket_pages/operations/ShippingCustomerManagement'))
const ShippingManagement = lazy(() => import('./pages/with_device_pocket_pages/operations/ShippingManagement'))
const PageStructure = lazy(() => import('./pages/with_device_pocket_pages/page_structure/PageStructure'))
const Permissions = lazy(() => import('./pages/with_device_pocket_pages/permissions/Permissions'))
const Planning = lazy(() => import('./pages/with_device_pocket_pages/planning/Planning'))
const Settings = lazy(() => import('./pages/with_device_pocket_pages/settings/Settings'))
const Setup = lazy(() => import('./pages/with_device_pocket_pages/setup/Setup'))
const SystemManagement = lazy(() => import('./pages/with_device_pocket_pages/system_management/SystemManagement'))
const UserManagemenet = lazy(() => import('./pages/with_device_pocket_pages/user_managemenet/UserManagemenet'))
const Modifications = lazy(() => import('./pages/with_device_pocket_pages/modifications/Modifications'))
const ChauffeurMonitoring = lazy(() => import('./pages/with_device_pocket_pages/chauffeur_monitoring/ChauffeurMonitoring2'))
const ResetPassword = lazy(() => import('./pages/with_device_pocket_pages/reset_password/ResetPassword'))
const SearchTool = lazy(() => import('./pages/with_device_pocket_pages/search_tool/SearchTool2'))
const ReportsAndGrraps = lazy(() => import('./pages/with_device_pocket_pages/reports_and_graphs/ReportsAndGrraps2'))




const DeviceFreeContainers = lazy(() => import('./pages/device_free_pocket_pages/containers/DeviceFreeContainers'))
const DeviceFreeHomePage = lazy(() => import('./pages/device_free_pocket_pages/home_page/DeviceFreeHomePage'))
const DeviceFreeMaps = lazy(() => import('./pages/device_free_pocket_pages/maps/DeviceFreeMaps'))
const DeviceFreeMapRouteManagement = lazy(() => import('./pages/device_free_pocket_pages/map_route_management/DeviceFreeMapRouteManagement'))
const DeviceFreeRouteControl = lazy(() => import('./pages/device_free_pocket_pages/map_route_management/DeviceFreeRouteControl'))
const DeviceFreeNotifications = lazy(() => import('./pages/device_free_pocket_pages/notifications/DeviceFreeNotifications'))
const DeviceFreePlanning = lazy(() => import('./pages/device_free_pocket_pages/planning/DeviceFreePlanning'))
const DeviceFreeSettings = lazy(() => import('./pages/device_free_pocket_pages/settings/DeviceFreeSettings'))
const DeviceFreeSystemManagement = lazy(() => import('./pages/device_free_pocket_pages/system_management/DeviceFreeSystemManagement'))
const DeviceFreeUserManagemenet = lazy(() => import('./pages/device_free_pocket_pages/user_managemenet/DeviceFreeUserManagemenet'))
const DeviceFreeChauffeurMonitoring = lazy(() => import('./pages/device_free_pocket_pages/chauffeur_monitoring/DeviceFreeChauffeurMonitoring'))
const DeviceFreeReportsAndGrraps = lazy(() => import('./pages/device_free_pocket_pages/reports_and_graphs/DeviceFreeReportsAndGrraps'))








// const library = ["places"];

const MemoizedLoadScript = React.memo(LoadScript);

function App() {
  //! TRANSLATE
  const { i18n } = useTranslation();
  const [isTokenExist] = useState(localStorage.getItem('token') ? true : false)
  const [isUserInfoExist] = useState(localStorage.getItem('user_info') ? true : false)
  const [isCompanyExist] = useState(localStorage.getItem('company') ? true : false)
  const [isGlobalInfoExist] = useState(localStorage.getItem('globalInfo') ? true : false)

  const themeController = useSelector(state => state.themeController)
  const company = useSelector(state => state.authController.company)

  try {

    return (
      <MemoizedLoadScript
        googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
        language={i18n.language}
        libraries={["places"]}
        version="weekly"
        loadingElement={<PageLoading />}
      >
        <div className={`App ${themeController.themeIsDark == true ? 'dark-theme' : 'light-theme'}`}>
          <Suspense fallback={<div style={{ width: '100vw', height: '100vh', margin: 'auto' }}><PageLoading /></div>}>
            <AntdModal />
            <ClassicModal />
            <ToastContainer />
            <Toasty />
            <Routes>
              {
                !isUserInfoExist || !isCompanyExist || !isGlobalInfoExist || !isTokenExist ?
                  <>
                    <Route path={routes.login.path} element={<Login />} />
                    <Route path={routes.password_reset.path} element={<ResetPassword />} />
                  </>
                  :
                  <>
                    {(() => {
                      if (company.company_pocket.permissions.MEDICAL_WASTE.display === true) {
                        return (
                          <>
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.HOME_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.home_page.path} element={<MedicalWasteHomePage />} />}
                            {/* {company.company_pocket.permissions.MEDICAL_WASTE.features.HOME_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.dashboard.path} element={<MedicalWasteHomePage />} />} */}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.REPORT_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.reports.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.PLANNING_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.operations.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.SETTINGS_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.settings.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.NOTIFICATION_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.notifications.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.INSTITUTIONS_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.institutions.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.LIVE_ROUTE_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.live_route.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.DEFINITIONS_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.definitions.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.USERS_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.users.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.MAPS_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.maps.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route exact path={routes.MEDICAL_WASTE.map_route_management.path} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route exact path={`${routes.MEDICAL_WASTE.map_route_management.check_route.path}/duty`} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route exact path={`${routes.MEDICAL_WASTE.map_route_management.check_route.path}/duty/:driver_id`} element={<MedicalWasteHomePage />} />}
                            {company.company_pocket.permissions.MEDICAL_WASTE.features.SYSTEM_MANAGEMENT_PAGE.display === true && <Route path={routes.MEDICAL_WASTE.system_management.path} element={<MedicalWasteHomePage />} />}
                            <Route path={routes.notFound.path} element={<Navigate to={routes.MEDICAL_WASTE.home_page.path} />} />
                          </>
                        )
                      }
                      else if (company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.display === true) {
                        return (
                          <>
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.HOME_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.home_page.path} element={<PageStructure page={<DeviceFreeHomePage />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.map_route_management.path} element={<PageStructure page={<DeviceFreeMapRouteManagement />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route exact path={`${routes.DEVICE_FREE_POCKET_PAGES.map_route_management.check_route.path}/duty`} element={<PageStructure page={<DeviceFreeRouteControl />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route exact path={`${routes.DEVICE_FREE_POCKET_PAGES.map_route_management.check_route.path}/duty/:driver_id`} element={<PageStructure page={<DeviceFreeRouteControl />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.NOTIFICATION_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.notifications.path} element={<PageStructure page={<DeviceFreeNotifications />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.MAPS_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.maps.path} element={<PageStructure page={<DeviceFreeMaps />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.DRIVER_MONITORING_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.chauffeur_monitoring.path} element={<DeviceFreeChauffeurMonitoring />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.CONTAINERS_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.containers.path} element={<PageStructure page={<DeviceFreeContainers />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.PLANNING_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.planning.path} element={<PageStructure page={<DeviceFreePlanning />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.USER_MANAGEMENT_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.user_managemenet.path} element={<PageStructure page={<DeviceFreeUserManagemenet />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.SETTINGS_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.settings.path} element={<PageStructure page={<DeviceFreeSettings />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.REPORT_PAGE.display === true && <Route exact path={routes.DEVICE_FREE_POCKET_PAGES.reports_and_graphs.path} element={<PageStructure page={<DeviceFreeReportsAndGrraps />} />} />}
                            {company.company_pocket.permissions.DEVICE_FREE_POCKET_PAGES.features.SYSTEM_MANAGEMENT_PAGE.display === true && <Route path={routes.DEVICE_FREE_POCKET_PAGES.system_management.path} element={<PageStructure page={<SystemManagement />} />} />}
                            <Route path={routes.notFound.path} element={<Navigate to={routes.DEVICE_FREE_POCKET_PAGES.home_page.path} />} />
                          </>
                        )
                      }
                      else if (company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.display === true) {
                        return (
                          <>
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.HOME_PAGE.display === true && <Route exact path={routes.WITH_DEVICE_POCKET_PAGES.home_page.path} element={<PageStructure page={<HomePage />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.map_route_managemenet.path} element={<PageStructure page={<MapRouteManagement />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route path={`${routes.WITH_DEVICE_POCKET_PAGES.check_route_managemenet_duty.path}`} element={<PageStructure page={<RouteControl />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.MAPS_ROUTE_MANAGEMENT_PAGE.display === true && <Route path={`${routes.WITH_DEVICE_POCKET_PAGES.check_route_managemenet_duty.path}/:driver_id`} element={<PageStructure page={<RouteControl />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.MAPS_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.maps.path} element={<PageStructure page={<Maps />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.DRIVER_MONITORING_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.chauffeur_monitoring.path} element={<ChauffeurMonitoring />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.CONTAINERS_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.container.path} element={<PageStructure page={<Containers />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.PLANNING_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.planning.path} element={<PageStructure page={<Planning />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.NOTIFICATION_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.notification.path} element={<PageStructure page={<Notifications />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.USER_MANAGEMENT_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.user_managemenet.path} element={<PageStructure page={<UserManagemenet />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.SETTINGS_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.settings.path} element={<PageStructure page={<Settings />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.DEFAULT_SETTINGS_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.constances_settings.path} element={<PageStructure page={<ConstanceSettings />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.SETUP_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.setup.path} element={<PageStructure page={<Setup />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.REPORT_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.report.path} element={<PageStructure page={<ReportsAndGrraps />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.DEVICE_MANAGEMENT_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.device_management.path} element={<PageStructure page={<DeviceManagement />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.DEVICE_SETTINGS_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.device_settings.path} element={<PageStructure page={<DeviceSettings />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.DEVICE_MODIFICATION_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.modifications.path} element={<PageStructure page={<Modifications />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.SYSTEM_MANAGEMENT_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.system_management.path} element={<PageStructure page={<SystemManagement />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.SYSTEM_MANAGEMENT_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.search_tool.path} element={<PageStructure page={<SearchTool />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.HOME_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.device_qr_operation.path} element={<PageStructure page={<DeviceQrOperation />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.HOME_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.device_box_qr_operation.path} element={<PageStructure page={<DeviceBoxOperation />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.HOME_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.device_box_shipment.path} element={<PageStructure page={<DeviceShipment />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.HOME_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.shipping_customer_managemenet.path} element={<PageStructure page={<ShippingCustomerManagement />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.HOME_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.shipping_managemenet.path} element={<PageStructure page={<ShippingManagement />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.HOME_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.device_test.path} element={<PageStructure page={<DeviceTest />} />} />}
                            {company.company_pocket.permissions.WITH_DEVICE_POCKET_PAGES.features.HOME_PAGE.display === true && <Route path={routes.WITH_DEVICE_POCKET_PAGES.permissions.path} element={<PageStructure page={<Permissions />} />} />}
                            <Route path={routes.notFound.path} element={<Navigate to={routes.WITH_DEVICE_POCKET_PAGES.home_page.path} />} />
                          </>
                        )
                      }
                    })()}
                    {/* {company.company_pocket.permissions.SYSTEM_MANAGEMENT_PAGE.display === true && <Route path={routes.system_management.path} element={<PageStructure page={<SystemManagement />} />} />} */}
                    <Route path={routes.password_reset.path} element={<ResetPassword />} />
                    <Route path={routes.notFound.path} element={<NotFound />} />
                  </>
              }
              <Route path={routes.notFound.path} element={<Navigate to={routes.login.path} />} />
            </Routes>
          </Suspense>
        </div>
      </MemoizedLoadScript>
    );
  } catch (error) {
    if (error) {
      console.log("error : ", error)
      console.log("error line number:", error.stack)
      // localStorage.clear()
    }
  }
}


export default App;
