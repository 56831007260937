import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './core/stores/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import history from './core/history/history';
import { enableMapSet } from 'immer';
import { ConfigProvider, theme } from 'antd';
import tr_TR from 'antd/locale/tr_TR';
import './i18n';


// Map ve Set desteğini etkinleştir
enableMapSet();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter history={history} future={{ 
      v7_startTransition: true,
      v7_relativeSplatPath: true 
    }}>
      {/* <React.StrictMode> */}
      <ConfigProvider
        locale={tr_TR}
        theme={{
          token: {
            colorPrimary: '#3544aa',
          },
          algorithm: theme.defaultAlgorithm,
          components: {
            Switch: {
              colorPrimary: '#5367ed',
              height: '28px',
            },
            Radio: {
              colorPrimary: '#5367ed',
              colorPrimaryHover: '#3544aa'
            },
            Button: {
              colorPrimary: '#5367ed',
              colorPrimaryHover: '#3544aa',
              fontSize: 13,
              size: 'small',
            },
            Checkbox: {
              colorPrimary: '#5367ed',
              colorPrimaryHover: '#3544aa'
            },
            DatePicker: {
              colorBgContainer: '#eaedf1'
            },
            Input: {
              margin: '0',
              colorBgContainer: '#eaedf1'
            },
            InputNumber: {
              margin: '0',
              colorBgContainer: '#eaedf1',
              borderRadius: '8px',
              border: '1px solid #e8e8e8',
              transition: 'all 0.3s ease',
            },
            Select: {
              colorBgContainer: '#eaedf1'
            },
            Table: {
              colorBgContainer: 'white',
              fontSize: 12,
            },
          },
        }}
      >
        {/* <ErrorBoundary> */}
        <App />
        {/* </LoadScript> */}
        {/* </ErrorBoundary> */}
      </ConfigProvider>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
