import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);


export const containerTabControllerSlice = createSlice({
    name: 'containerTabControllerSlice',
    initialState: {
        sidebarCollapsed: false,
        dateFilter: {
            startDate: dayjs().subtract(1, 'week').endOf('day').format('YYYY-MM-DD'),
            finishDate: dayjs().startOf('day').format('YYYY-MM-DD'),
        },
        containerNames: null,
        reportContainerPageMainTabTotalCount: null,
        reportContainerPageMainTabGraph: null,
        isReportContainerPageMainTabTotalCountLoading: false,
        isReportContainerPageMainTabGraphLoading: false,
        isContainerNamesLoading: false,
        visibleCharts: {
            sigqChart: true,
            mcutempChart: true,
            tempChart: true,
            batteryVrefChart: true,
            fireAlarmChart: true,
            fullAlarmChart: true,
            coverAlarmChart: true,
            batteryCoverAlarmChart: true,
            fullnessAlarmChart: true,
            hourlyRawdataCorrectedChart: true,
            batteryAnalysisChart: true
        }
    },

    reducers: {
        setSidebarCollapsed: (state, action) => {
            state.sidebarCollapsed = action.payload
        },
        setContainerNames: (state, action) => {
            state.containerNames = action.payload
        },
        setDateFilter: (state, action) => {
            state.dateFilter = action.payload
        },

        setReportContainerPageMainTabTotalCount: (state, action) => {
            state.reportContainerPageMainTabTotalCount = action.payload
            state.isReportContainerPageMainTabTotalCountLoading = false
        },

        setReportContainerPageMainTabGraph: (state, action) => {
            state.reportContainerPageMainTabGraph = action.payload
            state.isReportContainerPageMainTabGraphLoading = false
        },

        setIsReportContainerPageMainTabTotalCountLoading: (state, action) => {
            state.isReportContainerPageMainTabTotalCountLoading = action.payload
        },

        setIsReportContainerPageMainTabGraphLoading: (state, action) => {
            state.isReportContainerPageMainTabGraphLoading = action.payload
        },

        setIsContainerNamesLoading: (state, action) => {
            state.isContainerNamesLoading = action.payload
        },
        
        setChartVisibility: (state, action) => {
            const { chartId, isVisible } = action.payload;
            state.visibleCharts[chartId] = isVisible;
        },
        
        setAllChartsVisibility: (state, action) => {
            state.visibleCharts = action.payload;
        }
    }
})


export const {
    setSidebarCollapsed,
    setContainerNames,
    setDateFilter,
    setReportContainerPageMainTabTotalCount,
    setReportContainerPageMainTabGraph,
    setIsReportContainerPageMainTabTotalCountLoading,
    setIsReportContainerPageMainTabGraphLoading,
    setIsContainerNamesLoading,
    setChartVisibility,
    setAllChartsVisibility
} = containerTabControllerSlice.actions

export default containerTabControllerSlice.reducer


