import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);


export const staticMainTabControllerSlice = createSlice({
    name: 'staticMainTabControllerSlice',
    initialState: {
        reportPageMainTabStaticTotalCount: null,
        reportPageMainTabStaticGraph: null,
        isReportPageMainTabStaticTotalCountLoading: false,
        isReportPageMainTabStaticGraphLoading: false,
    },

    reducers: {
        setReportPageMainTabStaticTotalCount: (state, action) => {
            state.reportPageMainTabStaticTotalCount = action.payload
            state.isReportPageMainTabStaticTotalCountLoading = false
        },
        setReportPageMainTabStaticGraph: (state, action) => {
            state.reportPageMainTabStaticGraph = action.payload
            state.isReportPageMainTabStaticGraphLoading = false
        },
        setIsReportPageMainTabStaticTotalCountLoading: (state, action) => {
            state.isReportPageMainTabStaticTotalCountLoading = action.payload
        },
        setIsReportPageMainTabStaticGraphLoading: (state, action) => {
            state.isReportPageMainTabStaticGraphLoading = action.payload
        },
    }
})


export const {
    setReportPageMainTabStaticTotalCount,
    setReportPageMainTabStaticGraph,
    setIsReportPageMainTabStaticTotalCountLoading,
    setIsReportPageMainTabStaticGraphLoading,
} = staticMainTabControllerSlice.actions

export default staticMainTabControllerSlice.reducer


