export const routes = {
    asset_url: {
        path: `${process.env.REACT_APP_ASSET_URL}`,
        title: "BACKEND URL",
    },
    simple_url: {
        path: `${process.env.REACT_APP_URL}`,
        title: "Ana Sayfa",
        title_eng: "Home Page"
    },
    notFound: {
        path: "*",
        title: "Not Found",
        title_eng: "Not Found"
    },
    login: {
        path: "/login",
        title: "Giriş Sayfası",
        title_eng: "Login Page"
    },
    password_reset: {
        path: "/reset-password",
        title: "Şifre Sıfırlama",
        title_eng: "Password Reset"
    },
    WITH_DEVICE_POCKET_PAGES: {
        home_page: {
            path: "/",
            title: "Ana Sayfa",
            title_eng: "Home Page"
        },
        map_route_managemenet: {
            path: "/route-managemenet",
            title: "Harita Rota Yönetimi",
            title_eng: "Map Route Management",
            check_route: {
                path: "/check-route",
                title: "Rota Denetleme",
                title_eng: "Route Checking"
            },
        },
        check_route_managemenet_duty: {
            path: "/check-route/duty",
            title: "Rota Yönetimi",
            title_eng: "Route Management"
        },
        check_route_managemenet_duty_history: {
            path: "/check-route/duty/history",
            title: "Rota Yönetimi",
            title_eng: "Route Management"
        },
        maps: {
            path: "/maps",
            title: "Harita Ekranı",
            title_eng: "Container Map"
        },
        chauffeur_monitoring: {
            path: "/chauffeur-monitoring",
            title: "Şoför İzleme Ekranı",
            title_eng: "Live Route Tracking",
        },
        container: {
            path: "/container",
            title: "Konteyner",
            title_eng: "Container"
        },
        planning: {
            path: "/planning",
            title: "Planlama Ekranı",
            title_eng: "Planning"
        },
        notification: {
            path: "/notification",
            title: "Bildirim Ekranı",
            title_eng: "Notifications"
        },
        user_managemenet: {
            path: "/user-managemenet",
            title: "Kişi Ayarları",
            title_eng: "User Settings"
        },
        settings: {
            path: "/settings",
            title: "Ayarlar",
            title_eng: "Settings"
        },
        tablet_driver: {
            path: "/tablet-driver",
            title: "Şoför İşlemleri",
            title_eng: "Driver Operations"
        },
        setup: {
            path: "/setup",
            title: "Kurulum",
            title_eng: "Setup"
        },
        report: {
            path: "/report",
            title: "Rapor ve İstatistik",
            title_eng: "Reports and Statistics"
        },
        device_management: {
            path: "/device-management",
            title: "Cihaz Yönetimi",
            title_eng: "Device Management"
        },
        device_settings: {
            path: "/device-settings",
            title: "Cihaz Ayarları",
            title_eng: "Device Settings"
        },
        modifications: {
            path: "/modifications",
            title: "Cihaz Modifikasyonu",
            title_eng: "Device Modification"
        },
        system_management: {
            path: "/system-management",
            title: "Sistem Yönetimi",
            title_eng: "System Management"
        },
        device_qr_operation: {
            path: "/device-qr-operation",
            title: "Cihaz Qr İşlemi",
            title_eng: "Device QR Operation"
        },
        device_box_qr_operation: {
            path: "/device-box-qr-operation",
            title: "Cihaz Kutulama İşlemi",
            title_eng: "Device Boxing Operation"
        },
        device_box_shipment: {
            path: "/device-box-shipment",
            title: "Sevkiyat Oluştur",
            title_eng: "Create Shipment"
        },
        shipping_customer_managemenet: {
            path: "/shipping-customer-managemenet",
            title: "Müşteri İşlemleri",
            title_eng: "Customer Operations"
        },
        shipping_managemenet: {
            path: "/shipping-managemenet",
            title: "Sevkiyat Yönetimi",
            title_eng: "Shipment Management"
        },
        device_test: {
            path: "/device-test",
            title: "Cihaz Test",
            title_eng: "Device Test"
        },
        constances_settings: {
            path: "/constances",
            title: "Default Ayarlar",
            title_eng: "Default Settings"
        },
        permissions: {
            path: "/permissions",
            title: "İzin Kontrol",
            title_eng: "Permission Control"
        },
        database: {
            path: "/database",
            title: "Database",
            title_eng: "Database"
        },
        search_tool: {
            path: "/search-tool",
            title: "Search Tool",
            title_eng: "Search Tool"
        },
        device_assembly_operation: {
            path: "/device-assembly-operation",
            title: "Cihaz Montaj Operasyonu",
            title_eng: "Device Assembly Operation"
        },
        password_reset: {
            path: "/reset-password",
            title: "Şifre Sıfırlama",
            title_eng: "Password Reset"
        },
        app_version: {
            path: "/app-version",
            title: "Mobil Uygulama Versiyonu",
            title_eng: "App Version"
        },
    },
    DEVICE_FREE_POCKET_PAGES: {
        home_page: {
            path: "/",
            title: "Ana Sayfa",
            title_eng: "Home Page"
        },
        planning: {
            path: "/device_free_container/planning",
            title: "Planlama Ekranı",
            title_eng: "Planning Screen"
        },
        settings: {
            path: "/device_free_container/settings",
            title: "Ayarlar",
            title_eng: "Settings"
        },
        reports_and_graphs: {
            path: "/device_free_container/reports_and_graphs",
            title: "Raporlar ve Grafikler",
            title_eng: "Reports and Graphs"
        },
        system_management: {
            path: "/device_free_container/system_management",
            title: "Sistem Yönetimi",
            title_eng: "System Management"
        },
        user_managemenet: {
            path: "/device_free_container/user_managemenet",
            title: "Kullanıcı Yönetimi",
            title_eng: "User Management"
        },
        notifications: {
            path: "/device_free_container/notifications",
            title: "Bildirimler",
            title_eng: "Notifications"
        },
        containers: {
            path: "/device_free_container/containers",
            title: "Konteynerler",
            title_eng: "Containers"
        },
        container_type: {
            path: "/device_free_container/container_type",
            title: "Konteyner Tipi",
            title_eng: "Container Type"
        },
        chauffeur_monitoring: {
            path: "/device_free_container/chauffeur_monitoring",
            title: "Şoför İzleme Ekranı",
            title_eng: "Chauffeur Monitoring Screen"
        },
        maps: {
            path: "/device_free_container/maps",
            title: "Harita Ekranı",
            title_eng: "Maps Screen"
        },
        route_control: {
            path: "/device_free_container/route_control",
            title: "Rota Denetleme Ekranı",
            title_eng: "Route Control Screen"
        },
        map_route_management: {
            path: "/device_free_container/map_route_management",
            title: "Harita Rota Yönetimi",
            title_eng: "Map Route Management",
            check_route: {
                path: "/check-route",
                title: "Rota Denetleme",
                title_eng: "Route Checking"
            },
        },
    },
    MEDICAL_WASTE: {
        home_page: {
            path: "/medical-waste",
            title: "Tıbbi Atık",
            title_eng: "Medical Waste"
        },
        dashboard: {
            path: "/medical-waste/dashboard",
            title: "Ana Sayfa",
            title_eng: "Dashboard"
        },
        reports: {
            path: "/medical-waste/reports",
            title: "Raporlar",
            title_eng: "Reports"
        },
        operations: {
            path: "/medical-waste/operations",
            title: "Operasyonlar",
            title_eng: "Operations"
        },
        settings: {
            path: "/medical-waste/settings",
            title: "Ayarlar",
            title_eng: "Settings"
        },
        notifications: {
            path: "/medical-waste/notifications",
            title: "Bildirimler",
            title_eng: "Notifications"
        },
        institutions: {
            path: "/medical-waste/institutions",
            title: "Kurumlar",
            title_eng: "Institutions"
        },
        live_route: {
            path: "/medical-waste/live-route",
            title: "Canlı Rota",
            title_eng: "Live Route"
        },
        definitions: {
            path: "/medical-waste/definitions",
            title: "Tanımlar",
            title_eng: "Definitions"
        },
        users: {
            path: "/medical-waste/users",
            title: "Kullanıcılar",
            title_eng: "Users"
        },
        maps: {
            path: "/medical-waste/maps",
            title: "Harita",
            title_eng: "Maps"
        },
        map_route_management: {
            path: "/medical-waste/map_route_management",
            title: "Harita Rota Yönetimi",
            title_eng: "Map Route Management",
            check_route: {
                path: "/medical-waste/check-route",
                title: "Rota Denetleme",
                title_eng: "Route Checking"
            },
        },
        system_management: {
            path: "/medical-waste/system_management",
            title: "Sistem Yönetimi",
            title_eng: "System Management"
        },
    },
};
