import { createSlice } from '@reduxjs/toolkit'

export const deviceManagementControllerSlice = createSlice({
    name: 'deviceManagementController',
    initialState: {
        tableData: null,
        hasMore: true,
        currentPage: 1,
        loading: {
            deviceDataApi: true
        },
        infoCards: null,
        filteredInfo: {},
        searchText: "",
        searchedColumn: "",
    },

    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setHasMore: (state, action) => {
            state.hasMore = action.payload
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setInfoCards: (state, action) => {
            state.infoCards = action.payload
        },
        setFilteredInfo: (state, action) => {
            state.filteredInfo = action.payload
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload
        },
        setSearchedColumn: (state, action) => {
            state.searchedColumn = action.payload
        },
    }
})

export const { 
    setTableData, 
    setHasMore, 
    setCurrentPage, 
    setLoading,
    setInfoCards,
    setFilteredInfo,
    setSearchText,
    setSearchedColumn
} = deviceManagementControllerSlice.actions

export default deviceManagementControllerSlice.reducer


