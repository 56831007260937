import { createSlice } from '@reduxjs/toolkit'

export const ContainerTypeStatus = {
    allContainerStatus: 'allContainerStatus',
    noneConnectedDeviceStatus: 'noneConnectedDeviceStatus',
    connectedDeviceStatus: 'connectedDeviceStatus',
    fireAlarmStatus: 'fireAlarmStatus',
    coverAlarmStatus: 'coverAlarmStatus',
    batteryCoverAlarmStatus: 'batteryCoverAlarmStatus',
    connectionErrorStatus: 'connectionErrorStatus',
    todayCollectedStatus: 'todayCollectedStatus',
    redContainerStatus: 'redContainerStatus',
    yellowContainerStatus: 'yellowContainerStatus',
    greenContainerStatus: 'greenContainerStatus',
    watchingContainerStatus: 'watchingContainerStatus',
}


export const containerPageControllerSlice = createSlice({
    name: 'containerPageControllerSlice',
    initialState: {
        companyLocalStorage: JSON.parse(localStorage.getItem("company")),
        activeContainerType: ContainerTypeStatus.allContainerStatus,
        containerTypeDataStatus: {
            loading: false,
            error: false,
            success: false,
        },
        totalContainerData: {
            total_containers: 0,
            total_green_containers: 0,
            total_yellow_containers: 0,
            total_red_containers: 0,
            battery_cover_alarm_containers: 0,
            open_cover_alarm_containers: 0,
            fire_alarm_containers: 0,
            connection_error_containers: 0,
            collected_today_containers: 0,
            total_containers_with_device: 0,
            total_containers_without_device: 0,
            monitored_containers: 0,
            unmonitored_containers: 0
        },
        totalContainerDataLoading: false,
        tableData: null,
        hasMore: true,
        currentPage: 1,
        containerDataApiLoading: false,
        infoCards: null,
        filteredInfo: {},
        searchText: "",
        searchedColumn: "",
    },

    reducers: {
        setActiveContainerType: (state, action) => {
            state.activeContainerType = action.payload;
        },
        setContainerTypeDataStatus: (state, action) => {
            state.containerTypeDataStatus = action.payload;
        },
        setTotalContainerData: (state, action) => {
            state.totalContainerData = action.payload;
        },
        setTotalContainerDataLoading: (state, action) => {
            state.totalContainerDataLoading = action.payload;
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setHasMore: (state, action) => {
            state.hasMore = action.payload
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setContainerDataApiLoading: (state, action) => {
            state.containerDataApiLoading = action.payload
        },
        setInfoCards: (state, action) => {
            state.infoCards = action.payload
        },
        setFilteredInfo: (state, action) => {
            state.filteredInfo = action.payload
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload
        },
        setSearchedColumn: (state, action) => {
            state.searchedColumn = action.payload
        },
    }
})


export const {
    setActiveContainerType,
    setContainerTypeDataStatus,
    setTableData,
    setHasMore,
    setCurrentPage,
    setContainerDataApiLoading,
    setInfoCards,
    setFilteredInfo,
    setSearchText,
    setSearchedColumn,
    setTotalContainerData,
    setTotalContainerDataLoading,
} = containerPageControllerSlice.actions

export default containerPageControllerSlice.reducer


