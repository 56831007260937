import { createSlice } from '@reduxjs/toolkit'


export const reportControllerSlice = createSlice({
    name: 'reportControllerSlice',
    initialState: {

        staticTotalCountApiData: null,
        totalCountApiData: null,
        staticGraphApiData: null,
        graphApiData: null,
        selectedCardsForShowHideList: [
            'Toplam Konteyner Sayısı',
            'Toplam Araç Sayısı',
            'Toplam Tablet Sayısı',
            'Toplam Kullanıcı Sayısı',
            'Toplam Personel Sayısı',
            'Toplam Şoför Sayısı',
            'Toplam Bölge Sayısı',
            'Toplam Rota Sayısı',
            'Toplam Kullanıcı Tipi Sayısı',
            'Toplam Vardiya Sayısı',
            'Toplam Cihaz Sayısı',
            'Toplam Konteyner Türü Sayısı',
            'Toplam Yangın Alarmı Sayısı',
            'Toplam Şoförlerin Topladım Dediği Konteyner Sayısı',
            'Toplam Şoförlerin Harcadığı Rota Zamanı ( Saat )',
            'Toplam Şoförlerin Gittim Dediği KM ( KM )',
        ],
        staticSelectedCardsForShowHideList: [
            'Toplam Konteyner Sayısı',
            'Toplam Araç Sayısı',
            'Toplam Tablet Sayısı',
            'Toplam Kullanıcı Sayısı',
            'Toplam Personel Sayısı',
            'Toplam Şoför Sayısı',
            'Toplam Bölge Sayısı',
            'Toplam Rota Sayısı',
            'Toplam Kullanıcı Tipi Sayısı',
            'Toplam Vardiya Sayısı',
            'Toplam Cihaz Sayısı',
            'Toplam Konteyner Türü Sayısı',
            'Toplam Yangın Alarmı Sayısı',
            'Toplam Şoförlerin Topladım Dediği Konteyner Sayısı',
            'Toplam Şoförlerin Harcadığı Rota Zamanı ( Saat )',
            'Toplam Şoförlerin Gittim Dediği KM ( KM )',
        ],
        regionTabSelectedCardsForShowHideList: [
            'Harita Ekranı',
        ],
        regionTabStaticSelectedCardsForShowHideList: [
            'Harita Ekranı',
        ],
        selectedDateType: 'month',
        isStaticTotalCountApiDataLoading: true,
        isTotalCountApiDataLoading: true,
        isStaticGraphApiDataLoading: true,
        isGraphApiDataLoading: true,
        isRegionGraphApiDataLoading: true,
        regionGraphApiData: null,
        isDriverGraphApiDataLoading: true,
        driverGraphApiData: null,
        isVehicleGraphApiDataLoading: true,
        vehicleGraphApiData: null,

        //* ----------------------------------------------------------------
        //* ----------------------------------------------------------------
        //!******** OVERVİEW REPORT TAB **********/


        //* --------- TOTAL COUNT REPORT --------- */

        //* Toplam Toplanan Konteyner Sayısı - R1
        r1totalCollectedContainerCount: null,
        r1totalCollectedContainerCountLoading: true,

        //* Toplam Rota Zamanı Harcanan - R2
        r2totalRouteTimeSpent: null,
        r2totalRouteTimeSpentLoading: true,

        //* Toplam Gittim Dediği KM - R3
        r3totalDistanceTravelled: null,
        r3totalDistanceTravelledLoading: true,

        //* Toplam Konteyner Sayısı - R4
        r4totalContainerCount: null,
        r4totalContainerCountLoading: true,

        //* Toplam Şoför Sayısı - R5
        r5totalDriverCount: null,
        r5totalDriverCountLoading: true,

        //* Toplam Araç Sayısı - R6
        r6totalVehicleCount: null,
        r6totalVehicleCountLoading: true,

        //* Toplam Tablet Sayısı - R7
        r7totalTabletCount: null,
        r7totalTabletCountLoading: true,

        //* Toplam Kullanıcı Sayısı - R8
        r8totalUserCount: null,
        r8totalUserCountLoading: true,

        //* Toplam Personel Sayısı - R9
        r9totalEmployeeCount: null,
        r9totalEmployeeCountLoading: true,

        //* Toplam Konteyner Türü Sayısı - R10
        r10totalContainerTypeCount: null,
        r10totalContainerTypeCountLoading: true,

        //* Toplam Bölge Sayısı - R11
        r11totalRegionCount: null,
        r11totalRegionCountLoading: true,

        //* Toplam Rota Sayısı - R12
        r12totalRouteCount: null,
        r12totalRouteCountLoading: true,

        //* Toplam Kullanıcı Tipi Sayısı - R13
        r13totalUserTypeCount: null,
        r13totalUserTypeCountLoading: true,

        //* Toplam Vardiya Sayısı - R14
        r14totalShiftCount: null,
        r14totalShiftCountLoading: true,

        //* Toplam Cihaz Sayısı - R15
        r15totalDeviceCount: null,
        r15totalDeviceCountLoading: true,

        //* Toplam Tamamlanan Rota - R16
        r16totalFinishedRouteCount: null,
        r16totalFinishedRouteCountLoading: true,



        //* ------- GRAPH REPORT --------- */

        //* Konteyner Toplama Oranı Grafiği - G1
        g1totalContainerCollectionRateGraph: null,
        g1totalContainerCollectionRateGraphLoading: true,

        //* Molada Harcanan Rota Zamanı Grafiği - G2
        g2totalRouteTimeSpentInBreakGraph: null,
        g2totalRouteTimeSpentInBreakGraphLoading: true,

        //* Rotada Harcanan Rota Zamanı Grafiği - G3
        g3totalRouteTimeSpentInRouteGraph: null,
        g3totalRouteTimeSpentInRouteGraphLoading: true,

        //* Çöp Dökümde Harcanan Rota Zamanı Grafiği - G4
        g4totalRouteTimeSpentInDumpGraph: null,
        g4totalRouteTimeSpentInDumpGraphLoading: true,

        //* Günlük Toplanan Konteyner Grafiği - G5
        g5totalCollectedContainerGraph: null,
        g5totalCollectedContainerGraphLoading: true,

        //* En hızlı dolan bölge bazlı ilk 3 konteyner grafiği - G6
        g6fastestRegionContainerGraph: null,
        g6fastestRegionContainerGraphLoading: true,

        //* En Çok Konteyner Toplanan 10 Sokak Grafiği - G7
        g7mostContainerCollectedStreetGraph: null,
        g7mostContainerCollectedStreetGraphLoading: true,

        //* Bölgelere ait tamamlanan rota sayısı grafiği - G8
        g8finishedRegionRouteGraph: null,
        g8finishedRegionRouteGraphLoading: true,

        //* Günlük bazda gidilen km sayısı grafiği - G9
        g9totalDistanceTravelledGraph: null,
        g9totalDistanceTravelledGraphLoading: true,

        //* Bölgelere ait toplam konteyner sayısı grafiği - G10 
        g10totalRegionContainerCountGraph: null,
        g10totalRegionContainerCountGraphLoading: true,

        //* Son 6 ayın aylık topllanma verisi grafiği - G11
        g11totalRegionContainerCountGraph: null,
        g11totalRegionContainerCountGraphLoading: true,




        //* ----------------------------------------------------------------
        //* ----------------------------------------------------------------
        //!******** CONTAINER REPORT TAB **********/






    },

    reducers: {
        setSelectedCardsForShowHideList: (state, action) => {
            state.selectedCardsForShowHideList = action.payload
        },
        setSelectedDateType: (state, action) => {
            state.selectedDateType = action.payload
        },


        //* ----------------------------------------------------------------
        //* ----------------------------------------------------------------
        //!******** OVERVİEW REPORT TAB **********/


        //* --------- TOTAL COUNT REPORT --------- */
        //* Toplam Toplanan Konteyner Sayısı
        setR1totalCollectedContainerCount: (state, action) => {
            state.r1totalCollectedContainerCount = action.payload
        },

        //* Toplam Toplanan Konteyner Sayısı Loading
        setIsR1totalCollectedContainerCountLoading: (state, action) => {
            state.r1totalCollectedContainerCountLoading = action.payload
        },

        //* Toplam Rota Zamanı Harcanan
        setR2totalRouteTimeSpent: (state, action) => {
            state.r2totalRouteTimeSpent = action.payload
        },

        //* Toplam Rota Zamanı Harcanan Loading
        setIsR2totalRouteTimeSpentLoading: (state, action) => {
            state.r2totalRouteTimeSpentLoading = action.payload
        },

        //* Toplam Gittim Dediği KM
        setR3totalDistanceTravelled: (state, action) => {
            state.r3totalDistanceTravelled = action.payload
        },

        //* Toplam Gittim Dediği KM Loading
        setIsR3totalDistanceTravelledLoading: (state, action) => {
            state.r3totalDistanceTravelledLoading = action.payload
        },

        //* Toplam Konteyner Sayısı
        setR4totalContainerCount: (state, action) => {
            state.r4totalContainerCount = action.payload
        },

        //* Toplam Konteyner Sayısı Loading
        setIsR4totalContainerCountLoading: (state, action) => {
            state.r4totalContainerCountLoading = action.payload
        },

        //* Toplam Şoför Sayısı
        setR5totalDriverCount: (state, action) => {
            state.r5totalDriverCount = action.payload
        },

        //* Toplam Şoför Sayısı Loading
        setIsR5totalDriverCountLoading: (state, action) => {
            state.r5totalDriverCountLoading = action.payload
        },

        //* Toplam Araç Sayısı
        setR6totalVehicleCount: (state, action) => {
            state.r6totalVehicleCount = action.payload
        },

        //* Toplam Araç Sayısı Loading
        setIsR6totalVehicleCountLoading: (state, action) => {
            state.r6totalVehicleCountLoading = action.payload
        },

        //* Toplam Tablet Sayısı
        setR7totalTabletCount: (state, action) => {
            state.r7totalTabletCount = action.payload
        },

        //* Toplam Tablet Sayısı Loading
        setIsR7totalTabletCountLoading: (state, action) => {
            state.r7totalTabletCountLoading = action.payload
        },

        //* Toplam Kullanıcı Sayısı
        setR8totalUserCount: (state, action) => {
            state.r8totalUserCount = action.payload
        },

        //* Toplam Kullanıcı Sayısı Loading
        setIsR8totalUserCountLoading: (state, action) => {
            state.r8totalUserCountLoading = action.payload
        },

        //* Toplam Personel Sayısı
        setR9totalEmployeeCount: (state, action) => {
            state.r9totalEmployeeCount = action.payload
        },

        //* Toplam Personel Sayısı Loading
        setIsR9totalEmployeeCountLoading: (state, action) => {
            state.r9totalEmployeeCountLoading = action.payload
        },

        //* Toplam Konteyner Türü Sayısı
        setR10totalContainerTypeCount: (state, action) => {
            state.r10totalContainerTypeCount = action.payload
        },

        //* Toplam Konteyner Türü Sayısı Loading
        setIsR10totalContainerTypeCountLoading: (state, action) => {
            state.r10totalContainerTypeCountLoading = action.payload
        },

        //* Toplam Bölge Sayısı
        setR11totalRegionCount: (state, action) => {
            state.r11totalRegionCount = action.payload
        },

        //* Toplam Bölge Sayısı Loading
        setIsR11totalRegionCountLoading: (state, action) => {
            state.r11totalRegionCountLoading = action.payload
        },


        //* Toplam Rota Sayısı
        setR12totalRouteCount: (state, action) => {
            state.r12totalRouteCount = action.payload
        },

        //* Toplam Rota Sayısı Loading
        setIsR12totalRouteCountLoading: (state, action) => {
            state.r12totalRouteCountLoading = action.payload
        },

        //* Toplam Kullanıcı Tipi Sayısı
        setR13totalUserTypeCount: (state, action) => {
            state.r13totalUserTypeCount = action.payload
        },

        //* Toplam Kullanıcı Tipi Sayısı Loading
        setIsR13totalUserTypeCountLoading: (state, action) => {
            state.r13totalUserTypeCountLoading = action.payload
        },

        //* Toplam Vardiya Sayısı
        setR14totalShiftCount: (state, action) => {
            state.r14totalShiftCount = action.payload
        },

        //* Toplam Vardiya Sayısı Loading
        setIsR14totalShiftCountLoading: (state, action) => {
            state.r14totalShiftCountLoading = action.payload
        },

        //* Toplam Cihaz Sayısı
        setR15totalDeviceCount: (state, action) => {
            state.r15totalDeviceCount = action.payload
        },

        //* Toplam Cihaz Sayısı Loading
        setIsR15totalDeviceCountLoading: (state, action) => {
                state.r15totalDeviceCountLoading = action.payload
        },

        //* Toplam Tamamlanan Rota
        setR16totalFinishedRouteCount: (state, action) => {
            state.r16totalFinishedRouteCount = action.payload
        },

        //* Toplam Tamamlanan Rota Loading
        setIsR16totalFinishedRouteCountLoading: (state, action) => {
            state.r16totalFinishedRouteCountLoading = action.payload
        },




        //* ------- GRAPH REPORT --------- */

        //* Konteyner Toplama Oranı Grafiği
        setG1totalContainerCollectionRateGraph: (state, action) => {
                state.g1totalContainerCollectionRateGraph = action.payload
        },

        //* Konteyner Toplama Oranı Grafiği Loading
        setIsG1totalContainerCollectionRateGraphLoading: (state, action) => {
            state.g1totalContainerCollectionRateGraphLoading = action.payload
        },

        //* Molada Harcanan Rota Zamanı Grafiği
        setG2totalRouteTimeSpentInBreakGraph: (state, action) => {
            state.g2totalRouteTimeSpentInBreakGraph = action.payload
        },

        //* Molada Harcanan Rota Zamanı Grafiği Loading
        setIsG2totalRouteTimeSpentInBreakGraphLoading: (state, action) => {
            state.g2totalRouteTimeSpentInBreakGraphLoading = action.payload
        },

        //* Rotada Harcanan Rota Zamanı Grafiği
        setG3totalRouteTimeSpentInRouteGraph: (state, action) => {
            state.g3totalRouteTimeSpentInRouteGraph = action.payload
        },

        //* Rotada Harcanan Rota Zamanı Grafiği Loading
        setIsG3totalRouteTimeSpentInRouteGraphLoading: (state, action) => {
            state.g3totalRouteTimeSpentInRouteGraphLoading = action.payload
        },

        //* Çöp Dökümde Harcanan Rota Zamanı Grafiği
        setG4totalRouteTimeSpentInDumpGraph: (state, action) => {
            state.g4totalRouteTimeSpentInDumpGraph = action.payload
        },

        //* Çöp Dökümde Harcanan Rota Zamanı Grafiği Loading
        setIsG4totalRouteTimeSpentInDumpGraphLoading: (state, action) => {
            state.g4totalRouteTimeSpentInDumpGraphLoading = action.payload
        },

        //* Günlük Toplanan Konteyner Grafiği
        setG5totalCollectedContainerGraph: (state, action) => {
            state.g5totalCollectedContainerGraph = action.payload
        },

        //* Günlük Toplanan Konteyner Grafiği Loading
        setIsG5totalCollectedContainerGraphLoading: (state, action) => {
            state.g5totalCollectedContainerGraphLoading = action.payload
        },

        //* En hızlı dolan bölge bazlı ilk 3 konteyner grafiği
        setG6fastestRegionContainerGraph: (state, action) => {
            state.g6fastestRegionContainerGraph = action.payload
        },

        //* En hızlı dolan bölge bazlı ilk 3 konteyner grafiği Loading
        setIsG6fastestRegionContainerGraphLoading: (state, action) => {
            state.g6fastestRegionContainerGraphLoading = action.payload
        },

        //* En Çok Konteyner Toplanan 10 Sokak Grafiği
        setG7mostContainerCollectedStreetGraph: (state, action) => {
            state.g7mostContainerCollectedStreetGraph = action.payload
        },

        //* En Çok Konteyner Toplanan 10 Sokak Grafiği Loading
        setIsG7mostContainerCollectedStreetGraphLoading: (state, action) => {
            state.g7mostContainerCollectedStreetGraphLoading = action.payload
        },

        //* Bölgelere ait tamamlanan rota sayısı grafiği
        setG8finishedRegionRouteGraph: (state, action) => {
            state.g8finishedRegionRouteGraph = action.payload
        },

        //* Bölgelere ait tamamlanan rota sayısı grafiği Loading
        setIsG8finishedRegionRouteGraphLoading: (state, action) => {
            state.g8finishedRegionRouteGraphLoading = action.payload
        },

        //* Günlük bazda gidilen km sayısı grafiği
        setG9totalDistanceTravelledGraph: (state, action) => {
            state.g9totalDistanceTravelledGraph = action.payload
        },

        //* Günlük bazda gidilen km sayısı grafiği Loading
        setIsG9totalDistanceTravelledGraphLoading: (state, action) => {
            state.g9totalDistanceTravelledGraphLoading = action.payload
        },

        //* Bölgelere ait toplam konteyner sayısı grafiği
        setG10totalRegionContainerCountGraph: (state, action) => {
            state.g10totalRegionContainerCountGraph = action.payload
        },

        //* Bölgelere ait toplam konteyner sayısı grafiği Loading
        setIsG10totalRegionContainerCountGraphLoading: (state, action) => {
            state.g10totalRegionContainerCountGraphLoading = action.payload
        },



        //* ----------------------------------------------------------------
        //* ----------------------------------------------------------------
        //!******** CONTAINER REPORT TAB **********/





        //* ----------------------------------------------------------------
        //* ----------------------------------------------------------------
        //* ----------------------------------------------------------------
        setIsGraphApiDataLoading: (state, action) => {
            state.isGraphApiDataLoading = action.payload
        },
        setIsStaticGraphApiDataLoading: (state, action) => {
            state.isStaticGraphApiDataLoading = action.payload
        },
        setTotalCountApiData: (state, action) => {
            state.totalCountApiData = action.payload
        },
        setStaticTotalCountApiData: (state, action) => {
            state.staticTotalCountApiData = action.payload
        },
        setGraphApiData: (state, action) => {
            state.graphApiData = action.payload
        },
        setStaticGraphApiData: (state, action) => {
            state.staticGraphApiData = action.payload
        },
        setRegionGraphApiData: (state, action) => {
            state.regionGraphApiData = action.payload
        },
        setIsRegionGraphApiDataLoading: (state, action) => {
            state.isRegionGraphApiDataLoading = action.payload
        },
        setIsDriverGraphApiDataLoading: (state, action) => {
            state.isDriverGraphApiDataLoading = action.payload
        },
        setDriverGraphApiData: (state, action) => {
            state.driverGraphApiData = action.payload
        },
        setIsVehicleGraphApiDataLoading: (state, action) => {
            state.isVehicleGraphApiDataLoading = action.payload
        },
        setVehicleGraphApiData: (state, action) => {
            state.vehicleGraphApiData = action.payload
        },
        setIsTotalCountApiDataLoading: (state, action) => {
            state.isTotalCountApiDataLoading = action.payload
        },
        setIsStaticTotalCountApiDataLoading: (state, action) => {
            state.isStaticTotalCountApiDataLoading = action.payload
        },
    }
})


export const {
    setIsTotalCountApiDataLoading,
    setSelectedCardsForShowHideList,


    setR1totalCollectedContainerCount,
    setIsR1totalCollectedContainerCountLoading,
    setR2totalRouteTimeSpent,
    setIsR2totalRouteTimeSpentLoading,
    setR3totalDistanceTravelled,
    setIsR3totalDistanceTravelledLoading,
    setR4totalContainerCount,
    setIsR4totalContainerCountLoading,
    setTotalDriverCount,
    setIsR5totalDriverCountLoading,
    setR6totalVehicleCount,
    setIsR6totalVehicleCountLoading,
    setR7totalTabletCount,
    setIsR7totalTabletCountLoading,
    setR8totalUserCount,
    setIsR8totalUserCountLoading,
    setR9totalEmployeeCount,
    setIsR9totalEmployeeCountLoading,
    setR10totalContainerTypeCount,
    setIsR10totalContainerTypeCountLoading,
    setR11totalRegionCount,
    setIsR11totalRegionCountLoading,
    setR12totalRouteCount,
    setIsR12totalRouteCountLoading,
    setR13totalUserTypeCount,
    setIsR13totalUserTypeCountLoading,
    setR14totalShiftCount,
    setIsR14totalShiftCountLoading,
    setR15totalDeviceCount,
    setIsR15totalDeviceCountLoading,
    setR16totalFinishedRouteCount,
    setIsR16totalFinishedRouteCountLoading,
    setG1totalContainerCollectionRateGraph,
    setIsG1totalContainerCollectionRateGraphLoading,
    setG2totalRouteTimeSpentInBreakGraph,
    setIsG2totalRouteTimeSpentInBreakGraphLoading,
    setG3totalRouteTimeSpentInRouteGraph,
    setIsG3totalRouteTimeSpentInRouteGraphLoading,
    setG4totalRouteTimeSpentInDumpGraph,
    setIsG4totalRouteTimeSpentInDumpGraphLoading,
    setG5totalCollectedContainerGraph,
    setIsG5totalCollectedContainerGraphLoading,
    setG6fastestRegionContainerGraph,
    setIsG6fastestRegionContainerGraphLoading,
    setG7mostContainerCollectedStreetGraph,
    setIsG7mostContainerCollectedStreetGraphLoading,
    setG8finishedRegionRouteGraph,
    setIsG8finishedRegionRouteGraphLoading,
    setG9totalDistanceTravelledGraph,
    setIsG9totalDistanceTravelledGraphLoading,
    setG10totalRegionContainerCountGraph,
    setIsG10totalRegionContainerCountGraphLoading,






    setSelectedDateType,
    setIsStaticTotalCountApiDataLoading,
    setIsGraphApiDataLoading,
    setIsStaticGraphApiDataLoading,
    setTotalCountApiData,
    setStaticTotalCountApiData,
    setGraphApiData,
    setStaticGraphApiData,
    setRegionGraphApiData,
    setIsRegionGraphApiDataLoading,
    setIsDriverGraphApiDataLoading,
    setDriverGraphApiData,
    setIsVehicleGraphApiDataLoading,
    setVehicleGraphApiData,

} = reportControllerSlice.actions

export default reportControllerSlice.reducer


