import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger';
import globalControllerSlice from "./globalControllerSlice"
import menuControllerSlice from "./menuControllerSlice"
import modalControllerSlice from "./modalControllerSlice"
import nullCheckControllerSlice from './nullCheckControllerSlice';
import authControllerSlice from './authControllerSlice';
import deviceBoxOperationControllerSlice from './deviceBoxOperationControllerSlice';
import boxShipmentControllerSlice from './boxShipmentControllerSlice';
import companyControllerSlice from './companyControllerSlice';
import toastyControllerSlice from './toastyControllerSlice';
import globalInfoControllerSlice from './globalInformationControllerSlice';
import setupFilteredDataControllerSlice from './setupFilteredDataControllerSlice';
import ModificationStepsControllerSlice from './ModificationStepsControllerSlice';
import containersRealTimeStatusControllerSlice from './containersRealTimeStatusControllerSlice';
import deviceFreeContainersStatusControllerSlice from './deviceFreeContainersStatusControllerSlice';
import systemCheckControllerSlice from './systemCheckControllerSlice';
import containerStatusInfoControllerSlice from './containerStatusInfoControllerSlice';
import antdModalControllerSlice from './antdModalControllerSlice';
import mapControllerSlice from './mapControllerSclice';
import chauffeurWatchControllerSlice from './chauffeurWatchControllerSlice';
import themeControllerSlice from './themeControllerSlice';
import googleMapsControllerSlice from './googleMapsControllerSlice';
import routeControlControllerSlice from './routeControlControllerSlice';
import searchToolsControllerSlice from './searchToolsControllerSlice';
import globalInfoCardControllerSlice from './globalInfoCardControllerSlice';
import routeManagementControllerSlice from './routeManagementControllerSlice';
import reportControllerSlice from './reportControllerSlice';
import homePageControllerSlice from './homePageControllerSlice';
import deviceManagementControllerSlice from './deviceManagementControllerSlice';
import containerPageControllerSlice from './containerPageControllerSlice';
import mainTabControllerSlice from './report_tabs_controllers/mainTabControllerSlice';
import staticMainTabControllerSlice from './report_tabs_controllers/staticMainTabControllerSlice';
import containerTabControllerSlice from './report_tabs_controllers/containerTabControllerSlice';
export default configureStore({
  reducer: {
    globalController: globalControllerSlice,
    menuController: menuControllerSlice,
    modalController: modalControllerSlice,
    nullCheckController: nullCheckControllerSlice,
    authController: authControllerSlice,
    deviceBoxOperationController: deviceBoxOperationControllerSlice,
    boxShipmentController: boxShipmentControllerSlice,
    companyController: companyControllerSlice,
    toastyController: toastyControllerSlice,
    globalInfoController: globalInfoControllerSlice,
    setupFilteredDataController: setupFilteredDataControllerSlice,
    ModificationStepsController: ModificationStepsControllerSlice,
    containersRealTimeStatusController: containersRealTimeStatusControllerSlice,
    deviceFreeContainersStatusController: deviceFreeContainersStatusControllerSlice,
    containerStatusInfoController: containerStatusInfoControllerSlice,
    antdModalController: antdModalControllerSlice,
    systemCheckController: systemCheckControllerSlice,
    mapController: mapControllerSlice,
    chauffeurWatchController: chauffeurWatchControllerSlice,
    themeController: themeControllerSlice,
    googleMapsController: googleMapsControllerSlice,
    routeControlController: routeControlControllerSlice,
    searchToolsController: searchToolsControllerSlice,
    globalInfoCardController: globalInfoCardControllerSlice,
    routeManagementController: routeManagementControllerSlice,
    reportController: reportControllerSlice,
    homePageController: homePageControllerSlice,
    deviceManagementController: deviceManagementControllerSlice,
    containerPageController: containerPageControllerSlice,
    mainTabController: mainTabControllerSlice,
    staticMainTabController: staticMainTabControllerSlice,
    containerTabController: containerTabControllerSlice,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: false
    })
    // Bu middleware yapılandırması Redux store'un davranışını özelleştirir:
    // 1. getDefaultMiddleware() Redux Toolkit'in varsayılan middleware'lerini sağlar
    // 2. serializableCheck: false - Redux'un serileştirilebilirlik kontrolünü devre dışı bırakır,
    //    bu sayede serileştirilemeyen değerler (fonksiyonlar, Promise'ler, Date objeleri vb.) 
    //    store'da saklanabilir
    // 
    // Not: Eğer console'da Redux action'larını görmek isterseniz, aşağıdaki gibi logger'ı ekleyebilirsiniz:
    // getDefaultMiddleware({
    //   serializableCheck: false
    // }).concat(logger)
})